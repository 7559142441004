import React from "react"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { Link } from "gatsby"


// Components
import LayoutBase from "../Layout/LayoutBaseNoFooter"
import SEO from "../Seo"


// Style
import "../../styles/components/PageError/page-error.scss"


interface IPageErrorDefaultProps {
    pageId: string,
    pageTitle: string,
    location: any,
    crumbLabel: string,
    errorNumber: string,
    text1?: string,
    text2?: string,
    children?: any,
}

const PageError = (props: IPageErrorDefaultProps) => {
    const pageId = props.pageId
    const pageTitle = props.pageTitle
    const { crumbs } = useBreadcrumb({
        location: props.location,
        crumbLabel: props.crumbLabel,
    })


    return (
        <LayoutBase
            pageId={props.pageId}
            crumbs={crumbs}
        >
            <SEO pageTitle={pageTitle}/>
            <div id={pageId}>
                <div className="grid-container" style={{ height: "100%" }}>
                    <div className="grid-margin-x" style={{ height: "100%" }}>
                        <div className="grid-x align-center-middle" style={{ height: "100%" }}>
                            <div className="cell">
                                <h1>{props.errorNumber}</h1>
                                <p className="text1">{props.text1}</p>
                                <p className="text2">{props.text2}</p>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutBase>
    )
}
PageError.defaultProps = {
    text1: "",
    text2: "",
}


export default PageError
