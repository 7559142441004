import React from "react"
import { Link } from "gatsby"


// Components
import PageError from "../components/PageError"


interface IPageError503PageDefaultProps {
    location: any,
}

const PageError503Page = (props: IPageError503PageDefaultProps) => {
    const pageId = "pageError"
    const pageTitle = "503: Service Unavailable"
    const errorNumber = "503"
    const location = props.location
    const crumbLabel =  "サイトが大変混み合っています"
    const text1 = "サイトが大変混み合っています"
    const text2 = "アクセスが集中しページが閲覧しにくい状態となっています。お手数ですが時間をおいて再度アクセスしてください"

    return (
        <PageError
            pageId={pageId}
            pageTitle={pageTitle}
            crumbLabel={crumbLabel}
            location={location}
            errorNumber={errorNumber}
            text1={text1}
            text2={text2}
        />
    )
}

export default PageError503Page
