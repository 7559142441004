import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


// Components
import Typekit from "../Typekit"
import FontAwesome from "../FontAwesome"
import Header from "../HeaderV2"


// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core"


// コンポーネントなどで使用するアイコンを読み込む。ただしスタイルシートで使用するものは含めない。
import {
    faCheckCircle as fasCheckCircle,
    faExclamationCircle as fasExclamationCircle,
    faDotCircle as fasDotCircle,
} from "@fortawesome/pro-solid-svg-icons"

import {
    faPlusCircle as farPlusCircle,
    faUndoAlt as farUndoAlt,
} from "@fortawesome/pro-regular-svg-icons"

import {
    faCheck as falCheck,
    faAlignJustify as falAlignJustify,
    faTimes as falTimes,
    faTimesCircle as falTimesCircle,
    faHandPaper as falHandPaper,
    faSadTear as falSadTear,
    faCity as falCity,
    faBus as falBus,
    faCar as falCar,
    faArrowCircleRight as falArrowCircleRight,
    faArrowCircleLeft as falArrowCircleLeft,
} from "@fortawesome/pro-light-svg-icons"

import {
    faAsterisk as fabAsterisk,
} from "@fortawesome/pro-duotone-svg-icons"

// 読み込んだアイコンを追加する
library.add(fasCheckCircle, fasExclamationCircle, fasDotCircle)
library.add(farPlusCircle, farUndoAlt)
library.add(falCheck, falAlignJustify, falTimes, falTimesCircle, falHandPaper, falSadTear, falCity, falBus, falCar, falArrowCircleRight, falArrowCircleLeft)
library.add(fabAsterisk)


// Style
import "../../styles/main.scss"


/**
 * Layout base no footer component
 *
 */
interface ILayoutBaseProps {
    pageId: string,
    pageTitle?: string;
    crumbs: object[];
    headerSmallOfPageTop?: boolean,
    headerSmallOfSticky?: boolean,
    headerCoverUp?: boolean,
    children: React.ReactNode;
}

const LayoutBaseNoFooter = (props: ILayoutBaseProps) => {
    const { site } = useStaticQuery(graphql`
        query LayoutBaseNoFooterSiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)


    return (
        <Fragment>
            <Header
                pageId={props.pageId}
                crumbs={props.crumbs}
                siteTitle={site.siteMetadata.title}
                headerSmallOfPageTop={props.headerSmallOfPageTop}
                headerSmallOfSticky={props.headerSmallOfSticky}
                coverUp={props.headerCoverUp}
            />
            <main>
                {props.children}
            </main>
            <Typekit/>
            <FontAwesome/>
        </Fragment>
    )
}
LayoutBaseNoFooter.defaultProps = {
    pageTitle: ``,
    headerSmallOfPageTop: true,
    headerSmallOfSticky: true,
    headerCoverUp: false,
}

export default LayoutBaseNoFooter
